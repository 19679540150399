
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { AuthGetters, AuthActions } from '@/store/auth/types'

export default defineComponent({
  setup () {
    const windowWidth = ref(window.innerWidth)
    const route = useRoute()
    const router = useRouter()
    const pageTitle = route.meta.title
    const store = useStore()
    const userLogin = ref(computed(() => store.getters[AuthGetters.USER]))

    const onResize = () => {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
       window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    const logout = () => {
      store.dispatch(AuthActions.LOGOUT)
      router.replace({ name: 'LoginView' })
    }
    return {
      pageTitle,
      userLogin,
      windowWidth,
      logout
    }
  }
})
