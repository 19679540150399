import gql from 'graphql-tag'

export const SalesQuery = gql`
query ($type: SaleType!, $platform: [Platform]) {
  sales(type: $type, bu: [KP], platform: $platform) {
  	date
    gross
    grand
    net
    discount
    orders
    platform
    skus
    qty
    carat_used
  }
}
`;

export const SalesSubscription = gql`
subscription ($type: SaleType!, $platform: [Platform]) {
  sales(type: $type, bu: [KP], platform: $platform) {
    date
    gross
    grand
    net
    discount
    orders
    platform
    skus
    qty
    carat_used
  }
}
`;

export const HourlySalesQuery = gql`
query ($type: SaleType!) {
  hourlySales(type: $type, bu: [KP]) {
    date
    sales {
      hrs
      grand
      cumm_grand
      platform
    }
  }
}
`;

export const HourlySalesSubscription = gql`
subscription ($type: SaleType!) {
  hourlySales(type: $type, bu: [KP]) {
    hrs
    grand
    cumm_grand
    platform
  }
}
`;