import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": _ctx.defaultActive,
    class: _normalizeClass(["h-100 p-4 bg-main border-right-0", { 'fixed-width-menu': _ctx.isCollapse }]),
    collapse: _ctx.isCollapse,
    router: true
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeMatched, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_menu_item, {
          key: index,
          index: item.meta.fullPath,
          class: _normalizeClass([{ 'text-center': _ctx.isCollapse }, "text-info"])
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.meta.title), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, {
              icon: item.meta.icon,
              class: "mr-2"
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1032, ["index", "class"]))
      }), 128)),
      _createVNode(_component_fa_icon, {
        icon: "bars",
        class: _normalizeClass(["icon-collapse icon-collapse-open", { 'icon-collapse-close': _ctx.isCollapse }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCollapse = !_ctx.isCollapse))
      }, null, 8, ["class"])
    ]),
    _: 1
  }, 8, ["default-active", "collapse", "class"]))
}