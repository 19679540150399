import gql from 'graphql-tag'

export const PowerDealListQuery = gql`
query {
  powerdealList(bu:[KP]) {
  	collection_name
    title_name
    status
    start_date
    end_date
    unix_start_time
    unix_end_time
  }
}
`;

export const PowerDealSalesQuery = gql`
query ($collection: String!) {
  powerdealSales(bu:[KP], collection: $collection) {
    sales {
      date
      orders
      skus
      qty
      grand
      discount
      gross
      web
      ios
      android
      bu
    }
    hourly {
      hrs
      net
      cumm_net
      platform
    }
    skus
  }
}
`;

export const PowerDealSalesSubscription = gql`
subscription ($collection: String!) {
  powerdealSales(bu:[KP], collection: $collection) {
    sales {
      date
      orders
      skus
      qty
      grand
      discount
      gross
      web
      ios
      android
      bu
    }
    hourly {
      hrs
      net
      cumm_net
      platform
    }
  }
}
`;