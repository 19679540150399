
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const isCollapse = ref(false)
    if (window.innerWidth <= 768) {
      isCollapse.value = true
    }
    const route = useRoute()
    const routeMatched = ref(route.matched[0].children.filter((x: any) => !x.meta.hidden ))
    const defaultActive = ref(route.path)

    watch(route, (x) => {
      defaultActive.value = x.path
    })

    const onResize = () => {
      if (window.innerWidth <= 768) {
        isCollapse.value = true
      } else {
        isCollapse.value = false
      }
    }

    onMounted(() => {
       window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })
    return {
      defaultActive,
      isCollapse,
      routeMatched
    }
  }
})
