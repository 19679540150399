import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PagesLayout from '../layouts/PagesLayout.vue'
import AppMain from '../layouts/AppMain.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: AppMain,
    redirect: '/',
    children: [
      {
        path: '',
        name: 'OverviewView',
        component: () => import('../views/OverviewView.vue'),
        meta: {
          title: 'Overview',
          icon: 'gauge',
          key: '1',
          fullPath: '/'
        },
      },
      {
        path: 'powerdeal',
        name: 'PowerDeal',
        component: () => import('../views/PowerDealView.vue'),
        meta: {
          title: 'Power Deal',
          icon: 'stopwatch',
          key: '3',
          fullPath: '/powerdeal'
        }
      },
      {
        path: '/powerdeal/:name',
        name: 'PowerDealDashboard',
        component: () => import('../views/PowerDealDashboardView.vue'),
        meta: {
          title: 'Power Deal Dashboard',
          hidden: true,
          key: '3',
          fullPath: '/powerdeal/:name'
        }
      },
      {
        path: 'stock',
        name: 'StockMonitorView',
        component: () => import('../views/StockMonitorView.vue'),
        meta: {
          title: 'Stock Monitor',
          icon: 'desktop',
          key: '2',
          fullPath: '/stock'
        }
      },
    ]
  },
  {
    path: '',
    component: PagesLayout,
    children: [
      {
        path: 'login',
        name: 'LoginView',
        component: () => import('../views/LoginView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
