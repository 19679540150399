import { SalesState } from './types'

export const state: SalesState = {
  today: {
    all: [],
    platform: []
  },
  last7days: {
    all: [],
    platform: []
  },
  hourly: []
}
