import Cookies from 'js-cookie'
const expiresDate = new Date()
expiresDate.setFullYear(expiresDate.getFullYear() + 10)

export function getUser () {
  return Cookies.get('user')
}

export function getToken () {
  return Cookies.get('token')
}

export function setUser (user: string) {
  return Cookies.set('user', user, { expires: expiresDate })
}

export function setToken (token: string) {
  return Cookies.set('token', token, { expires: expiresDate })
}

export function removeUser () {
  return Cookies.remove('user')
}

export function removeToken () {
  return Cookies.remove('token')
}
