import { PaymentsSalesQuery } from './graphql'
import { useQuery } from '@vue/apollo-composable'
import { PaymentsMutations } from './types'

export const actions = {
  getPayments (context: any) {
    const { onResult } = useQuery(
      PaymentsSalesQuery,
      { fetchPolicy: 'no-cache' }
    )
    onResult((result: any) => {
      if (result.data) {
        context.commit(PaymentsMutations.SET_PAYMENTS, result.data.paymentSale)
      }
    })
  }
}
