export interface TopSalesResponse {
  name: string
  sku: string
  net: number
  quantity: number
  discount: number
  gross: number
  orders: number
  web: number
  ios: number
  android: number
}

export interface TopSalesState {
  sku: Array<TopSalesResponse>
}

export interface PayloadActionsTopSales {
  type: string
  limit?: number
}

export enum TopSalesType {
  SKU = 'SKU',
  CATE = 'CATEGORIES',
  BRANDS = 'BRANDS',
  CODES = 'PROMOCODES'
}

export enum TopSalesGetters {
  SKU = 'top/sku',
}

export enum TopSalesActions {
  GET_TOPSALES = 'top/getTopSales'
}

export enum TopSalesMutations {
  SET_SKU = 'SET_SKU',
  SET_TOP_SKU = 'top/SET_SKU',
}
