import gql from 'graphql-tag'

export const TopSalesQuery = gql`
query ($type: TopSaleType!, $limit: Int) {
  topSales(type: $type, bu: [KP], limit: $limit) {
    sku
  	name
    quantity
    orders
    web
    ios
    android
    gross
    discount
    net
  }
}
`;

export const TopSalesSubscription = gql`
subscription ($type: TopSaleType!, $limit: Int) {
  topSales(type: $type, bu: [KP], limit: $limit) {
  	sku
  	name
    quantity
    orders
    web
    ios
    android
    gross
    discount
    net
  }
}
`;