import { ApolloClient, InMemoryCache, HttpLink, split} from '@apollo/client/core'
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities'
import { createApolloProvider } from '@vue/apollo-option'

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_URL_GRAPHQL
});

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_WS_LINK_URL!,
  options: {
    reconnect: true
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
)

export const apolloProvider = createApolloProvider({
  defaultClient: new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })
})