import { PowerDealListQuery, PowerDealSalesQuery } from './graphql'
import { PowerDealMutations, PayloadActionsPowerDeal } from './types'
import { useQuery } from '@vue/apollo-composable'

export const actions = {
  getList (context: any) {
    const { onResult } = useQuery(
      PowerDealListQuery,
      { fetchPolicy: 'no-cache' }
    )
    onResult((result: any) => {
      if (result.data) {
        context.commit(PowerDealMutations.SET_LIST, result.data.powerdealList)
      }
    })
  },
  async getSales (context: any, payload: PayloadActionsPowerDeal) {
    context.commit(PowerDealMutations.SET_COLLECTION, payload.collection)
    const { onResult } = useQuery(
      PowerDealSalesQuery,
      { collection: payload.collection },
      { fetchPolicy: 'no-cache' }
    )
    const returnResult = await new Promise((resolve, reject) => {
      onResult(result => {
        if (result.data) {
          context.commit(PowerDealMutations.SET_SALES, result.data.powerdealSales.sales)
          context.commit(PowerDealMutations.SET_HOURLY, result.data.powerdealSales.hourly)
          context.commit(PowerDealMutations.SET_SKU, result.data.powerdealSales.skus)
        }
        resolve(result.data.powerdealSales.skus)
      })
    })
    return returnResult
  },
}
