const moment = require('moment-timezone')

export const mutations = {
  SET_LIST (state: any, data: any) {
    state.list = data.map((x: any) => {
      return {
        ...x,
        start_format: moment.unix(x.unix_start_time).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        end_format: moment.unix(x.unix_end_time).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
      }
    })
  },
  SET_SALES (state: any, data: any) {
    state.sales = data
  },
  SET_HOURLY (state: any, data: any) {
    state.hourly = data
  },
  SET_LAST_HOURLY (state: any, data: any) {
    state.hourly.map((item: any) => {
      const sales = data.find((x: any) => x.hrs === item.hrs && x.platform === x.platform)
      if (sales) {
        return sales
      }
      return { ...item }
    })
  },
  SET_SKU (state: any, data: any) {
    state.sku = data
  },
  SET_COLLECTION (state: any, data: any) {
    state.collection = data
  },
}
