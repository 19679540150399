import gql from 'graphql-tag'

export const StockQuery = gql`
query ($page: Int, $limit: Int, $sortBy: StocksSortBy, $sortType: SortType, $skus: [String], $search: String, $searchBy: StockSearchBy) {
  stocks (bu:[KP], sortType: $sortType, sortBy: $sortBy, limit: $limit, page: $page, skus: $skus, search: $search, searchBy: $searchBy) {
  	stocks {
    	sku
      category
      name
      orders
      net
      sold
      stock
  	}
    total
    limit
    page
    sortBy
    sortType
  }
}
`;

export const StockSubscription = gql`
subscription ($page: Int, $limit: Int, $sortBy: StocksSortBy, $sortType: SortType, $skus: [String], $search: String, $searchBy: StockSearchBy) {
  stocks (bu:[KP], sortType: $sortType, sortBy: $sortBy, limit: $limit, page: $page, skus: $skus, search: $search, searchBy: $searchBy) {
  	stocks {
    	sku
      category
      name
      orders
      net
      sold
      stock
  	}
    total
    limit
    page
    sortBy
    sortType
  }
}
`;