import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, { class: "bg-main" }, {
      default: _withCtx(() => [
        _createVNode(_component_Header)
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, { class: "height-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_main, { class: "bg-main py-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}