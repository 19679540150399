export enum Platform {
  ALL = 'All',
  WEB = 'Web',
  IOS = 'iOS',
  ANDROID = 'Android'
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC'
}