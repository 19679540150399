import router from '@/router'
import store from '@/store'
import { AuthGetters } from '@/store/auth/types'

router.beforeEach((to, from, next) => {
  if (store.getters[AuthGetters.TOKEN] === '' || !store.getters[AuthGetters.TOKEN]) {
    if (to.path !== '/login') {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  }
})