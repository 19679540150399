import { createApp, h, provide } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloProvider } from './apollo/apolloProvider'
import { apolloClient } from './apollo/apolloClient'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGauge, faBars, faDesktop, faCircle, faStopwatch, faArrowLeftLong, faCircleExclamation, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import VueApexCharts from 'vue3-apexcharts'

import * as filters from './filters'

import '@/assets/scss/index.scss'
import '@/utils/permission'

library.add(faGauge, faBars, faDesktop, faCircle, faStopwatch, faArrowLeftLong, faCircleExclamation, faEllipsis)

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

app.config.globalProperties.$filters = filters

app
.component('fa-icon', FontAwesomeIcon)
.use(ElementPlus)
.use(VueApexCharts)
.use(store)
.use(router)
.use(apolloProvider)
.mount('#app')
