import { StockState, StockSortBy, StockSearchBy } from './types'
import { SortType } from '../types'

export const state: StockState = {
  stock: [],
  pagination: {
    total: 0,
    limit: 30,
    page: 0,
  },
  sort: {
    by: StockSortBy.NET,
    type: SortType.DESC
  },
  search: {
    string: '',
    by: StockSearchBy.SKU
  }
}
