import { StockQuery } from './graphql'
import { StockMutations, PayloadActionsStock } from './types'
import { apolloClient } from '@/apollo/apolloClient'
import { useQuery, provideApolloClient } from '@vue/apollo-composable'

export const actions = {
  getStock (context: any, payload: PayloadActionsStock) {
    const { onResult } = provideApolloClient(apolloClient)(() => useQuery(
      StockQuery,
      { limit: payload.limit,
        page: payload.page,
        sortBy: payload.sortBy,
        sortType: payload.sortType,
        skus: payload.skus,
        search: payload.search || '',
        searchBy: payload.searchBy
      },
      { fetchPolicy: 'no-cache' }
    ))
    onResult((result: any) => {
      if (result.data) {
        context.commit(StockMutations.SET_STOCK, result.data.stocks.stocks)
        context.commit(StockMutations.SET_PAGINATION, result.data.stocks)
        context.commit(StockMutations.SET_SORT, result.data.stocks)
        context.commit(StockMutations.SET_SEARCH, { string: payload.search, by: payload.searchBy })
      }
    })
  },
  async getStockCSV (context: any, payload: PayloadActionsStock) {
    const { onResult } = provideApolloClient(apolloClient)(() => useQuery(
      StockQuery,
      { sortBy: payload.sortBy, sortType: payload.sortType, skus: payload.skus },
      { fetchPolicy: 'no-cache' }
    ))
    const returnResult = await new Promise((resolve, reject) => {
      onResult(result => {
        resolve(result.data.stocks.stocks)
      })
    })
    return returnResult
  }
}
