import { Platform } from "../types";
export interface SalesResponse {
  date: string
  gross: number
  grand: number
  discount: number
  net: number
  orders: number
  platform: Platform
  skus: number
  qty: number
  carat_used: number
}

export interface HourlySalesResponse {
  hrs: string
  grand: number
  cumm_grand: number
  platform: Platform
}

export interface SalesPlatform {
  all: Array<SalesResponse>
  platform: Array<SalesResponse>
}

export interface SalesState {
  today: SalesPlatform
  last7days: SalesPlatform
  hourly: Array<HourlySalesResponse>
}

export interface PayloadActionsSales {
  type: string
}

export enum SalesType {
  TODAY = 'Today',
  LAST7DAYS = 'Last7Days'
}

export enum SalesGetters {
  TODAY = 'sales/today',
  LAST7DAYS = 'sales/last7days',
  HOURLY = 'sales/hourly'
}

export enum SalesActions {
  GET_SALES = 'sales/getSales',
  GET_HOURLY_SALES = 'sales/getHourly',
}

export enum SalesMutations {
  SET_TODAY = 'SET_TODAY',
  SET_SALES_TODAY = 'sales/SET_TODAY',
  SET_LAST7DAYS = 'SET_LAST7DAYS',
  SET_SALES_LAST7DAYS = 'sales/SET_LAST7DAYS',
  SET_HOURLY = 'SET_HOURLY',
  SET_SALES_HOURLY = 'sales/SET_HOURLY',
  SET_SALES_LAST_HOURLY = 'sales/SET_LAST_HOURLY'
}
