import { Platform } from '../types'

export interface PowerDealListResponse {
  collection_name: string
  title_name: string
  status: string
  start_date: string
  end_date: string
  unix_start_time: number
  unix_end_time: number
}

export interface PowerDealSalesResponse {
  date: string
  orders: number
  skus: number
  qty: number
  grand: number
  discount: number
  gross: number
  web: number
  ios: number
  android: number
}

export interface PowerDealHourlyResponse {
  hrs: string
  net: number
  cumm_net: number
  platform: Platform
}

export interface PayloadActionsPowerDeal {
  collection: string
}

export interface PowerDealState {
  list: Array<PowerDealListResponse>
  sales: Array<PowerDealSalesResponse>
  hourly: Array<PowerDealHourlyResponse>
  sku: Array<string>
  collection: string
}

export enum PowerDealGetters {
  LIST = 'powerdeal/list',
  SALES = 'powerdeal/sales',
  HOURLY = 'powerdeal/hourly',
  SKU = 'powerdeal/sku',
  COLLECTION = 'powerdeal/collection'
}

export enum PowerDealActions {
  GET_LIST = 'powerdeal/getList',
  GET_SALES = 'powerdeal/getSales'
}

export enum PowerDealMutations {
  SET_LIST = 'SET_LIST',
  SET_POWERDEAL_LIST = 'powerdeal/SET_LIST',
  SET_SALES = 'SET_SALES',
  SET_POWERDEAL_SALES = 'powerdeal/SET_SALES',
  SET_HOURLY = 'SET_HOURLY',
  SET_POWERDEAL_HOURLY = 'powerdeal/SET_HOURLY',
  SET_LAST_HOURLY = 'SET_LAST_HOURLY',
  SET_POWERDEAL_LAST_HOURLY = 'powerdeal/SET_LAST_HOURLY',
  SET_SKU = 'SET_SKU',
  SET_POWERDEAL_SKU = 'powerdeal/SET_SKU',
  SET_COLLECTION = 'SET_COLLECTION',
  SET_POWERDEAL_COLLECTION = 'powerdeal/SET_COLLECTION'
}
