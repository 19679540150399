import gql from 'graphql-tag'

export const PaymentsSalesQuery = gql`
query {
  paymentSale (bu: [KP]) {
    paymentMethod
    grand
    orders
  }
}
`;

export const PaymentsSalesSubscription = gql`
subscription {
  paymentSale (bu: [KP]) {
    paymentMethod
    grand
    orders
  }
}
`;