import gql from 'graphql-tag'

export const Login = gql(`
query ($email: String!, $password: String!) {
	login(email: $email, password: $password, bu: KP) {
    isSuccess
    accessToken
    errorMessage
  }
}
`)