import { createStore } from 'vuex'
import { AuthModule } from './auth'
import { SalesModule } from './sales'
import { PaymentsModule } from './payments'
import { TopSalesModule } from './top-sales'
import { StockModule } from './stock'
import { PowerDealModule } from './powerdeal'

export default createStore({
  state: {},
  getters: { },
  mutations: {},
  actions: {},
  modules: {
    auth: AuthModule,
    sales: SalesModule,
    payments: PaymentsModule,
    top: TopSalesModule,
    stock: StockModule,
    powerdeal: PowerDealModule
  }
})
