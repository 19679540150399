import { SortType } from '../types'

export interface StockResponse {
  sku: string
  category: string
  name: string
  orders: number
  net: number
  sold: number
  stock: number
}

export interface Pagination {
  total: number
  limit: number
  page: number
}

export interface Search {
  string: any
  by: StockSearchBy
}

export interface Sort {
  by: StockSortBy
  type: SortType
}

export enum StockSortBy {
  CATEGORY = 'Category',
  ORDERS = 'Orders',
  SOLD = 'Sold',
  STOCK = 'Stock',
  NET = 'Net'
}

export enum StockSearchBy {
  SKU = 'SKU',
  NAME = 'ProductName'
}

export interface PayloadActionsStock {
  limit?: number
  page?: number
  sortBy?: StockSortBy
  sortType?: SortType
  skus?: Array<string>
  search?: string
  searchBy?: StockSearchBy
}

export interface StockState {
  stock: Array<StockResponse>
  pagination: Pagination
  sort: Sort
  search: Search
}


export enum StockGetters {
  STOCK = 'stock/stock',
  PAGINATION = 'stock/pagination',
  SORT = 'stock/sort',
  SEARCH = 'stock/search'
}

export enum StockActions {
  GET_STOCK = 'stock/getStock',
  GET_CSV = 'stock/getStockCSV'
}

export enum StockMutations {
  SET_STOCK = 'SET_STOCK',
  SET_STOCK_STOCK = 'stock/SET_STOCK',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_STOCK_PAGINATION = 'stock/SET_PAGINATION',
  SET_SORT = 'SET_SORT',
  SET_STOCK_SORT = 'stock/SET_SORT',
  SET_SEARCH = 'SET_SEARCH',
  SET_STOCK_SEARCH = 'stock/SET_SEARCH'
}
