export interface PaymentsSalesResponse {
  paymentMethod: string
  grand: number
  orders: number
}

export interface PaymentsState {
  payments: Array<PaymentsSalesResponse>
}

export enum PaymentsGetters {
  PAYMENT = 'payments/payments',
}

export enum PaymentsActions {
  GET_PAYMENTS = 'payments/getPayments'
}

export enum PaymentsMutations {
  SET_PAYMENTS = 'SET_PAYMENTS',
  SET_PAYMENTS_PAYMENTS = 'payments/SET_PAYMENTS'
}

export enum PaymentMethodType {
  CREDITCARD = 'CREDITCARD',
  ALIPAY = 'ALIPAY',
  INSTALMENT = 'INSTALMENT',
  KPLUSAPP = 'KPLUSAPP',
  UNIONPAY = 'UNIONPAY',
  SCBEASYAPP = 'SCBEASYAPP',
  PAYLATER_ATOME = 'PAYLATER-ATOME'
}
