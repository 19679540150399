<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.91);
}

.h-layout {
  min-height: calc(100vh - 162px);
  @media (max-width: 420px) {
    min-height: calc(100vh - 224px);
  }
}

.lg-tr {
  tr {
    height: 54px;
  }
}
.md-tr {
  tr {
    height: 38px;
  }
  .el-table__cell {
    padding: 0 !important;
  }
}

.fixed-height-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
