import { setUser, setToken, removeToken, removeUser } from "@/utils/auth"

export const mutations = {
  SET_USER (state: any, data: any) {
    state.user = data
    setUser(data)
  },
  SET_TOKEN (state: any, data: any) {
    state.token = data
    setToken(data)
  },
  REMOVE_USER (state: any) {
    state.user = ''
    removeUser()
  },
  REMOVE_TOKEN (state: any) {
    state.token = ''
    removeToken()
  }
}