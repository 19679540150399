import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_NavMenuDesktop = _resolveComponent("NavMenuDesktop")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_NavMenuMobile = _resolveComponent("NavMenuMobile")!
  const _component_el_footer = _resolveComponent("el-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, { class: "bg-main" }, {
      default: _withCtx(() => [
        _createVNode(_component_Header)
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, { class: "height-container" }, {
      default: _withCtx(() => [
        (_ctx.windowWidth > 420)
          ? (_openBlock(), _createBlock(_component_el_aside, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_NavMenuDesktop)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_main, { class: "bg-main py-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.windowWidth <= 420)
      ? (_openBlock(), _createBlock(_component_el_footer, {
          key: 0,
          class: "p-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NavMenuMobile)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}