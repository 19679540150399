import { AuthMutations, PayloadActionsLogin } from './types'
import { apolloClient } from '@/apollo/apolloClient'
import { useQuery, provideApolloClient } from '@vue/apollo-composable'
import { Login } from './graphql'

export const actions = {
  async login (context: any, payload: PayloadActionsLogin) {
    const { onResult } = provideApolloClient(apolloClient)(() => useQuery(
      Login,
      { email: payload.email, password: payload.password },
      { fetchPolicy: 'no-cache' }
    ))
    const returnResult = await new Promise((resolve, reject) => {
      onResult(result => {
        if (result.data.login.isSuccess) {
          context.commit(AuthMutations.SET_USER, payload.email)
          context.commit(AuthMutations.SET_TOKEN, result.data.login.accessToken)
        }
        resolve(result.data.login)
      })
    })
    return returnResult
  },
  logout (context: any) {
    context.commit(AuthMutations.REMOVE_USER)
    context.commit(AuthMutations.REMOVE_TOKEN)
  },
}