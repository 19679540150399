
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { AuthActions } from '@/store/auth/types'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const routeMatched = ref(route.matched[0].children.filter((x: any) => !x.meta.hidden))
    const mainRoute = ref(routeMatched.value.filter(item => { return item.children === undefined }))
    const subRoute = ref(routeMatched.value.filter(item => { return item.children }))
    const defaultActive = ref(route.path)
    const store = useStore()

    const logout = () => {
      store.dispatch(AuthActions.LOGOUT)
      router.replace({ name: 'LoginView' })
    }

    return {
      defaultActive,
      mainRoute,
      subRoute,
      logout
    }
  }
})
