export interface PayloadActionsLogin {
  email: string,
  password: string
}

export interface AuthState {
  user: any,
  token: any,
}

export enum AuthMutations {
  SET_USER = 'SET_USER',
  SET_TOKEN = 'SET_TOKEN',
  REMOVE_USER = 'REMOVE_USER',
  REMOVE_TOKEN = 'REMOVE_TOKEN'
}

export enum AuthGetters {
  USER = 'auth/user',
  TOKEN = 'auth/token'
}

export enum AuthActions {
  LOGIN = 'auth/login',
  LOGOUT = 'auth/logout'
}