export function asPrice (value: Number) {
  if (value) {
    return parseFloat(String(value).replace(/,/g, '')).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  } else {
    return 0.00
  }
}

export function asNumber (value: Number) {
  if (value) {
      return value.toFixed().replace(/(\d)(?=(\d{3})+$)/, '$&,')
  } else {
      return 0
  }
}