
import { defineComponent, ref, onBeforeUnmount, onMounted  } from 'vue'
import Header from './components/Header.vue'

export default defineComponent({
  name: 'PageLayout',
  components: {
    Header
  },
  setup () {
    const windowWidth = ref(window.innerWidth)
    const vh = ref(window.innerHeight * 0.01)
    document.documentElement.style.setProperty('--vh', `${vh.value}px`)

    const onResize = () => {
      windowWidth.value = window.innerWidth
      vh.value = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh.value}px`)
    }
    onMounted(() => {
       window.addEventListener('resize', onResize)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })
    return {
      windowWidth
    }
  }
})
