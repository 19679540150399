import { TopSalesQuery } from './graphql'
import { useQuery } from '@vue/apollo-composable'
import { PayloadActionsTopSales, TopSalesMutations } from './types'

export const actions = {
  getTopSales (context: any, payload: PayloadActionsTopSales) {
    const { onResult } = useQuery(
      TopSalesQuery,
      { type: payload.type, limit: payload.limit },
      { fetchPolicy: 'no-cache' }
    )
    onResult((result: any) => {
      if (result.data) {
        context.commit(TopSalesMutations.SET_SKU, result.data.topSales)
      }
    })
  }
}
