export const mutations = {
  SET_STOCK (state: any, data: any) {
    state.stock = data
  },
  SET_PAGINATION (state: any, data: any) {
    state.pagination.total = data.total
    state.pagination.limit = data.limit
    state.pagination.page = data.page
  },
  SET_SORT (state: any, data: any) {
    state.sort.by = data.sortBy
    state.sort.type = data.sortType
  },
  SET_SEARCH (state: any, data: any) {
    state.search.string = data.string
    state.search.by = data.by
  }
}
