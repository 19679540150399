import { SalesQuery, HourlySalesQuery } from './graphql'
import { useQuery } from '@vue/apollo-composable'
import { PayloadActionsSales, SalesType, SalesMutations } from './types'

export const actions = {
  getSales (context: any, payload: PayloadActionsSales) {
    const { onResult } = useQuery(
      SalesQuery,
      { type: payload.type },
      { fetchPolicy: 'no-cache' }
    )
    onResult((result: any) => {
      if (result.data) {
        const setMutation = (payload.type === SalesType.TODAY) ? SalesMutations.SET_TODAY : SalesMutations.SET_LAST7DAYS
        context.commit(setMutation, result.data.sales)
      }
    })
  },
  getHourly (context: any, payload: PayloadActionsSales) {
    const { onResult } = useQuery(
      HourlySalesQuery,
      { type: payload.type },
      { fetchPolicy: 'no-cache' }
    )
    onResult((result: any) => {
      if (result.data) {
        context.commit(SalesMutations.SET_HOURLY, result.data.hourlySales.sales)
      }
    })
  }
}
