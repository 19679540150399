import { Platform } from '../types'
const moment = require('moment-timezone')

export const mutations = {
  SET_TODAY (state: any, data: any) {
    state.today.all = data.filter((x: any) => { return x.platform === Platform.ALL })
    state.today.platform = data.filter((x: any) => { return x.platform !== Platform.ALL })
  },
  SET_LAST7DAYS (state: any, data: any) {
    const day = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
    state.last7days.all = data.filter((x: any) => { return x.platform === Platform.ALL && x.date !== day })
    state.last7days.platform = data.filter((x: any) => { return x.platform !== Platform.ALL && x.date !== day })
  },
  SET_HOURLY (state: any, data: any) {
    state.hourly = data
  },
  SET_LAST_HOURLY (state: any, data: any) {
    state.hourly.map((item: any) => {
      const sales = data.find((x: any) => x.hrs === item.hrs && x.platform === x.platform)
      if (sales) {
        return sales
      }
      return { ...item }
    })
  }
}
